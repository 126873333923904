<template>
  <div id="app" >
    <div class="loadingScreen noise-effect">
      <div class="left">
        <i class="bi bi-moon-fill"></i>
      </div>
      <div class="right">
        <i class="bi bi-moon-fill"></i>
      </div>
      <i class="bi bi-sun-fill left-sun"></i>
      <i class="bi bi-sun-fill right-sun"></i>
      <div class="text">
        <p>Welcome to my personal website!</p>
      </div>
      <div class="text2">
        <p>Be kind</p>
      </div>
      <div class="text3">
        <p>Talent wins games, teamwork wins championships</p>
      </div>
    </div>
    <div id="luxy">
      <LandingPage />
    </div>
  </div>
</template>

<script>
import Footer from "./components/Footer.vue";
import LandingPage from "./views/LandingPage.vue";





export default {
  components: {
    LandingPage
  },

  mounted () {

    const loadingScreenDiv = document.querySelector(".loadingScreen")
    const loadingScreenRigth = document.querySelector(".loadingScreen .right")
    const loadingScreenLeft = document.querySelector(".loadingScreen .left")
    const loadingScreenFirstText = document.querySelector(".loadingScreen .text")
    const loadingScreenSecondText = document.querySelector(".loadingScreen .text2")
    const loadingScreenThirdText = document.querySelector(".loadingScreen .text3")

    loadingScreenFirstText.classList.add("textOpacityIncrease")


    setTimeout(()=> {
      loadingScreenFirstText.classList.add("textOpacityDecrease")
    },2000)

    setTimeout(()=> {
      loadingScreenLeft.classList.add("slideLeft")
      loadingScreenRigth.classList.add("slideRight")
      loadingScreenFirstText.style.display = "none"
    },3000)

    setTimeout(()=>{
      loadingScreenSecondText.classList.add("textOpacityIncrease")
    },3200)
   
    setTimeout(()=> {
      loadingScreenLeft.style.display = "none"
      loadingScreenRigth.style.display = "none"
      loadingScreenSecondText.style.opacity = 1
    },4000)

    setTimeout(()=> {
      loadingScreenSecondText.classList.add("textOpacityDecrease")
    },5000)

    setTimeout(()=> {
      loadingScreenSecondText.style.display = "none"
      loadingScreenThirdText.classList.add("textOpacityIncrease")
    },6000)

    setTimeout(()=> {
      loadingScreenThirdText.style.opacity = 1
    },7000)

    setTimeout(()=> {
      loadingScreenThirdText.classList.add("textOpacityDecrease")
    },9000)

    setTimeout(()=> {
      loadingScreenDiv.classList.add("slideUp")
      loadingScreenThirdText.style.display = "none"
    },10000)

    setTimeout(()=> {
      loadingScreenDiv.style.display = "none"
    },11000)

  
    
    
    

  }

};
</script>

<style>

html {
  font-family: Coolvetica !important;
  scroll-behavior: smooth !important;
}

@font-face {
  font-family: "Coolvetica";
  src: local("Coolvetica"),
  url(./assets/fonts/coolvetica_regular.otf) format("truetype");
}

@font-face {
  font-family: "Poppins";
  src: local("Poppins"),
  url(./assets/fonts/Poppins-Regular.otf) format("truetype");
}

@font-face {
  font-family: "FuturaPT";
  src: local("FuturaPT"),
  url(./assets/fonts/FuturaPTMedium.otf) format("truetype");
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  overscroll-behavior: none;
  scroll-behavior: smooth;
}

body::-webkit-scrollbar {
  display: none;
}

body {
  display: flex;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

:root {
  --white: #F6F4F2;
  --black: #2E2C2C; 
  --red: #dd4040;
  --yellow: #ffc107;
  --blue: #29B5F1;
}


.noise-effect::before {
    position: fixed;
    content: "";
    background-image: url(./assets/images/noise.gif);
    opacity: 0.03;
    pointer-events: none;
    height: 100%;
    width: 100vw;
    object-fit: cover;
    left: 0;
  }


.loadingScreen {
    position: fixed;
    content: "";
    cursor: default;
    pointer-events: none;
    height: 100vh;
    width: 100vw;
    left: 0;
    z-index: 5;
    display: flex;
    background-color: #f6f4f2;
  }

  .loadingScreen .left {
    background-color: #161616;
    width: 50vw;
    height: 100%;
    display: flex;
    z-index: 1;
  }

  .loadingScreen .right {
    background-color: #161616;
    width: 50vw;
    height: 100%;
    z-index: 1;
  }

  .loadingScreen .left i {
    position: absolute;
    left: 150px;
    bottom: 200px;
    color: #F6F4F2;
    animation: rotation 1s infinite;
    font-size: 20px;
  }

  .loadingScreen .right i {
    position: absolute;
    right: 150px;
    bottom: 200px;
    color: #F6F4F2;
    animation: rotation 1s infinite;
    font-size: 20px;
  }

  .loadingScreen .left-sun, .loadingScreen .right-sun {
    position: absolute;
    color: #161616;
    font-size: 20px;
  }

  .loadingScreen .left-sun {
    left: 150px;
    bottom: 200px;
    animation: rotation 1s infinite;
  }

  .loadingScreen .right-sun {
    right: 150px;
    bottom: 200px;
    animation: rotation 1s infinite;
  }


  .slideRight {
    animation: openRight 1s ease;
  }

  .slideLeft {
    animation: openLeft 1s ease;
  }

  .slideUp {
    animation: openUp 1s ease;
  }

  .textOpacityIncrease {
    animation: opacityIncrease 1s ease;
  }

  .textOpacityDecrease {
    animation: opacityDecrease 1s ease;
  }



  .loadingScreen .text, .loadingScreen .text2, .loadingScreen .text3 {
    position: fixed;
    z-index: 2;
    width: 100%;
    height: 100%;
    display: flex;
    font-family: Coolvetica;
    font-size: 24px;
    justify-content: center;
    align-items: center;
  }

  .loadingScreen .text2 {
    opacity: 0;
  }

  .loadingScreen .text3 {
    opacity: 0;
  }

  .loadingScreen .text p {
    color: #f6f4f2;
  }


  @keyframes openRight {
	0% {
		transform: translateX(0%);
	}
	100% {
		transform: translateX(100%);
	}
}

@-webkit-keyframes openRight {
	0% {
		-webkit-transform: translateX(0%);
	}
	100% {
		-webkit-transform: translateX(100%);
	}
}

  @keyframes openLeft {
	0% {
		transform: translateX(0%);
	}
	100% {
		transform: translateX(-100%);
	}
  }

  @-webkit-keyframes openLeft {
	  0% {
	  	-webkit-transform: translateX(0%);
	  }
	  100% {
	  	-webkit-transform: translateX(-100%);
	  }
  }

  @keyframes openUp {
	  0% {
	  	transform: translateY(0%);
	  }
	  100% {
	  	transform: translateY(-100%);
	  }
  }

  @-webkit-keyframes openUp {
	  0% {
	  	-webkit-transform: translateY(0%);
	  }
	  100% {
	  	-webkit-transform: translateY(-100%);
	  }
  }

  @keyframes opacityIncrease {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @-webkit-keyframes opacityIncrease {
    0% {
      -webkit-opacity: 0;
    }
    100% {
      -webkit-opacity: 1;
    }
  }

  @keyframes opacityDecrease {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }

  @-webkit-keyframes opacityDecrease {
    0% {
      -webkit-opacity: 1;
    }
    100% {
      -webkit-opacity: 0;
    }
  }

  @keyframes rotation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  @-webkit-keyframes rotation {
    0% {
      -webkit-transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
    }
  }

  @media only screen and (max-width: 1000px){
    .loadingScreen .right i {
      right: 15px;
      bottom: 15px;
      font-size: 18px;
    }

    .loadingScreen .left i {
      left: 15px;
      bottom: 15px;
      font-size: 18px;
    }

    .loadingScreen .left-sun  {
      left: 15px;
      bottom: 15px;
      font-size: 18px;
    }

    .loadingScreen .right-sun  {
      right: 15px;
      bottom: 15px;
      font-size: 18px;
    }

    .loadingScreen .text, .loadingScreen .text2, .loadingScreen .text3 {
      font-size: 22px;
    }
  }


/* NAVBAR */

#nav {
  position: absolute;
  width: 100%;
  background-color: transparent !important;
  display: flex;
  justify-content: center;
  z-index: 2;
  cursor: default;
  font-family: Coolvetica !important;
  padding-top: 20px;
  padding-bottom: 20px;
}

.row {
  margin: 0 !important;
}

#magicMouseCursor {
  border: 2px solid #929292  !important;
}


#magicPointer {
  display: none;
}
</style>

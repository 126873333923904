import Vue from 'vue'
import App from './App.vue'
import VueRouter from 'vue-router';
import router from './router'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import AOS from 'aos';
import { magicMouse } from 'magicmouse.js'
import 'aos/dist/aos.css'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import 'bootstrap-icons/font/bootstrap-icons.css'



Vue.config.productionTip = false
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
AOS.init();


let options = {
  "cursorOuter": "disable",
  "hoverEffect": "pointer-blur",
  "hoverItemMove": false,
  "defaultCursor": false,
  "outerWidth": 30,
  "outerHeight": 30
}; 
magicMouse(options);
Vue.use(magicMouse)
new Vue({
  router,
  render: h => h(App),
}).$mount('#app')

import Vue from 'vue'
import VueRouter from 'vue-router'
import landingPage from '../views/LandingPage.vue'

Vue.use(VueRouter)


const routes = [
    {
        path: '/',
        name: 'landingPage',
        component: landingPage
    }
]


const router = new VueRouter({
    mode: 'history',
    routes,
    scrollBehavior () {
      return { x: 0, y: 0 }
    }
  })


  export default router

  
<template>
  <div class="page">
    <div class="noise-effect">
    </div>
    <div class="sector1">
      <div class="top-nav">
        <a @click="gotoemail()">Open to work</a>
        <a @click="gotolinkedin()">LinkedIn</a>
      </div>
      <div class="row">
        <div class="col-2"></div>
        <div class="col-4">
          <div class="left">
            <div class="letters">
              <div class="paragraph1">
                <span class="letter letterAnimating" id="H" @mouseenter="textAnimation" data-aos="fade-right" data-aos-duration='850'>H</span>
                <span class="letter letterAnimating" @mouseenter="textAnimation" data-aos="fade-right" data-aos-duration='850'>i</span>
              </div>
              <div class="paragraph2">
                <span class="letter letterAnimating" @mouseenter="textAnimation" data-aos="fade-right" data-aos-duration='1000'>I</span>
                <span class="letter letterAnimating" @mouseenter="textAnimation" data-aos="fade-right" data-aos-duration='1000'>'</span>
                <span class="letter letterAnimating" @mouseenter="textAnimation" data-aos="fade-right" data-aos-duration='1000'>m</span>
                <img class="imglogo" src="../assets/images/logo9.svg" alt="" data-aos="fade-right" data-aos-duration='1000'>
                <span class="letter letterAnimating" @mouseenter="textAnimation" data-aos="fade-right" data-aos-duration='1000'>i</span>
                <span class="letter letterAnimating" @mouseenter="textAnimation" data-aos="fade-right" data-aos-duration='1000'>o</span>
                <span class="letter letterAnimating" @mouseenter="textAnimation" data-aos="fade-right" data-aos-duration='1000'>g</span>
                <span class="letter letterAnimating" @mouseenter="textAnimation" data-aos="fade-right" data-aos-duration='1000'>o</span>
                <span class="letter letterAnimating" @mouseenter="textAnimation" data-aos="fade-right" data-aos-duration='1000'>,</span>
              </div>
            </div>
            <h4 id="front-end-developer" data-aos="fade-right" data-aos-duration='850' >&#60; Web Developer /&#62;</h4>
            <a href="mailto:dlmalhao@gmail.com">
              <div class="button sector1button" data-aos="fade-right"  data-aos-duration='1000' >
                <h4>Let's have a chat!</h4>
              </div>
            </a>
          </div>
        </div>
        <div class="col-4 map">
          <div class="right" data-aos="fade-left" data-aos-duration='1000' >
            <div class="imgMapa">
              <div class="locationContainer">
                <div class="header">
                  <h3>Porto, Portugal</h3>
                </div>
                <div class="content">
                  <img src="../assets/images/porto.jpg" alt="">
                </div>
              </div>
              <div class="location" id="locationTag" @click="showLocation">
                <i class="bi bi-geo-alt-fill"></i>
              </div>
            </div>
          </div>
        </div>
        <div class="col-2"></div>
        <div class="scrollArrow">
          <i class="bi bi-caret-down"></i>
      </div>
      <div class="bottom-nav">
        <p>Keep looking up...</p>
        <p>...positivity always wins!</p>
      </div>
      </div>
    </div>
    <div class="sector2" id="sector2">
      <div class="row">
        <div class="col-2"></div>
        <div class="col-8 header">
          <h4 class="text-overlay magic-hover magic-hover__square white" data-aos="fade-up" data-aos-duration='750'>WORK</h4>
          <!-- <h3 class="magic-hover magic-hover__square textShadow" id="work" data-aos="fade-up" data-aos-duration='750'>WORK</h3> -->
          <h2 class="textHeader magic-hover magic-hover__square" id="myWork" data-aos="fade-down" data-aos-duration='750'>WORK</h2>
        </div>
        <div class="col-2"></div>
      </div>
      <div class="svgContainer">
        <svg width="46" height="2085" viewBox="0 0 46 2085" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path id="svgLine" d="M16.906 2C19.755 2.44542 21.7657 12.031 23.2975 15.1446C28.3231 25.3592 31.6557 36.2104 31.6557 48.7364C31.6557 78.1865 24.8794 106.848 23.0244 136.213C20.5273 175.744 25.3719 210.397 33.8408 247.981C37.9111 266.045 40.8341 285.556 42.909 304.326C44.7886 321.328 44.8091 342.582 38.3203 357.596C21.1426 397.345 14.3093 443.77 17.1246 490.119C18.7234 516.44 25.9109 542.556 29.1428 568.602C32.2138 593.352 32.7434 620.351 31.4371 645.394C30.0277 672.416 23.331 696.073 19.3643 722.263C14.3279 755.517 10.5762 788.901 12.208 823.116C13.4736 849.651 20.788 874.881 24.2262 900.83C27.3535 924.433 29.6247 949.741 26.7391 973.625C23.7651 998.242 17.7937 1021.68 14.0654 1046.04C9.59919 1075.21 12.3554 1106.3 13.7376 1135.67C15.1839 1166.4 21.1964 1195.6 24.2808 1225.99C26.6449 1249.27 28.2088 1278.41 24.554 1301.55C15.571 1358.43 -0.670663 1412.68 2.37496 1472.2C3.68286 1497.76 10.2328 1521.51 14.4478 1546.15C18.1443 1567.75 20.0077 1587.59 19.856 1609.79C19.6927 1633.69 14.0744 1655.76 11.4979 1679.13C8.78109 1703.77 8.31807 1732.35 12.208 1756.77C16.3118 1782.53 23.5688 1806.22 29.689 1831.1C34.8197 1851.96 36.5722 1873.17 36.5722 1895.13C36.5722 1912.96 33.2978 1931.12 29.689 1948.17C25.9132 1966.01 24.608 1983.15 24.7725 2001.67C24.914 2017.6 29.9073 2032.41 30.6177 2048.41C30.9619 2056.16 27.8904 2077.7 31.6557 2083" stroke="#CACACA" stroke-width="4" stroke-linecap="round"/>
        </svg>




      </div>
      <div class="right-work">
        <div class="row">
          <div class="col-2 first"></div>
          <div class="col-4 work-img" data-aos="fade-right" data-aos-duration='1000' >
            <a href="https://github.com/dlmalhao/NBA-Golden-State-Warriors">
              <img src="../assets/images/nba.webp" alt="">
            </a>
          </div>
          <div class="col-4 align-center writing" data-aos="fade-left" data-aos-duration='1000'>
            <div class="content">
              <h3>College Project</h3>
              <h4 class="title">Golden State Warriors</h4>
              <div class="description">
                <h3>This A-Frame project was made in April 2021. It consists in a <span class="red">field replica</span> of the NBA team, <span class="red">Golden State Warriors.</span></h3>
              </div>
              <div class="work-tools">
                <h4>JS</h4>
                <h4>HTML</h4>
                <h4>A-Frame</h4>
              </div>
              <div class="links">
                <a href="https://github.com/dlmalhao/NBA-Golden-State-Warriors">
                  <i class="bi bi-github"></i>
                </a>
              </div>
            </div>
          </div>
          <div class="col-2 last"></div> 
        </div>
      </div>
      <div class="work-mobile" data-aos="fade-left" data-aos-duration='1000'>
        <div class="image">
          <a href="https://github.com/dlmalhao/NBA-Golden-State-Warriors">
            <img src="../assets/images/nba.webp" alt="">
          </a>
        </div>
        <div class="title">
          <div class="side-color"></div>
          <h3>Golden State Warriors</h3>
        </div>
        <div class="text">
          <h3>This A-Frame project was made in April 2021. It consists in a field replica of the NBA team, Golden State Warriors.</h3>
        </div>
        <div class="button-container">
          <div class="button" @click="gotonba">
            <p>More info</p>
          </div>
        </div>
      </div>
      <div class="left-work">
        <div class="row">
          <div class="col-2 first-col"></div>
          <div class="col-4 second-col work-img" data-aos="fade-left" data-aos-duration='1000'>
            <a href="https://github.com/dlmalhao/Buckle">
              <img id="buckle" src="../assets/images/buckle.webp" alt="">
            </a>
          </div>
          <div class="col-4 third-col align-center writing" data-aos="fade-right" data-aos-duration='1000'>
            <div class="content">
              <h3>College Project</h3>
              <h4 class="title">Buckle</h4>
              <div class="description">
                <h3>Buckle is a website, made in 2021/2022, designed to help and connect all students of a certain college. It has an <span class="red">Ads</span> section that provides a space for the students to ask for some help to the other students. It also has a <span class="red">Projects</span> section to publish their college projects.</h3>
              </div>
              <div class="work-tools">
                <h4>VueJS</h4>
                <h4>Heroku</h4>
                <h4>MySQL</h4>
                <h4>Buckle REST API</h4>
                <h4>Bootstrap</h4>
                <h4>Node.js</h4>
              </div>
              <div class="links">
                <a href="https://github.com/dlmalhao/Buckle">
                  <i class="bi bi-github"></i>
                </a>
              </div>
            </div>
          </div>
          <div class="col-2 fourth-col"></div> 
        </div>
      </div>
      <div class="work-mobile" data-aos="fade-right" data-aos-duration='1000'>
        <div class="image">
          <a href="https://github.com/dlmalhao/Buckle">
            <img id="buckle" src="../assets/images/buckle.webp" alt="">
          </a>
        </div>
        <div class="title">
          <div class="side-color"></div>
          <h3>Buckle</h3>
        </div>
        <div class="text">
          <h3>Buckle is a website, made in 2021/2022, designed to help and connect all students of a certain college. It has an Ads section that provides a space for the students to ask for some help to the other students. It also has a Projects section to publish their college projects.</h3>
        </div>
        <div class="button-container">
          <div class="button" @click="gotobuckle">
            <p>More info</p>
          </div>
        </div>
      </div>
      <div class="right-work">
        <div class="row">
          <div class="col-2 first"></div>
          <div class="col-4 work-img" data-aos="fade-right" data-aos-duration='1000'>
            <a href="https://github.com/dlmalhao/Asteroids-Game">
              <img src="../assets/images/asteroids.webp" alt="">
            </a>
          </div>
          <div class="col-4 align-center writing" data-aos="fade-left" data-aos-duration='1000'>
            <div class="content">
              <h3>College Project</h3>
              <h4 class="title">Asteroids</h4>
              <div class="description">
                <h3>Asteroids is a remastered version of the classic game “Asteroids”. It has some <span class="red">differences</span> in its game rules, and it counts with <span class="red">3 different color themes.</span>. This game also has <span class="red">background music</span> and <span class="red">sound effects</span>, as well as <span class="red">multimedia controls</span> in its options menu. </h3>
              </div>
              <div class="work-tools">
                <h4>JS</h4>
                <h4>HTML</h4>
                <h4>Canvas</h4>
              </div>
              <div class="links">
                <a href="https://github.com/dlmalhao/Asteroids-Game">
                  <i class="bi bi-github"></i>
                </a>
              </div>
            </div>
          </div>
          <div class="col-2 last"></div> 
        </div>
      </div>
      <div class="work-mobile" data-aos="fade-left" data-aos-duration='1000'>
        <div class="image">
          <a href="https://github.com/dlmalhao/Asteroids-Game">
            <img src="../assets/images/asteroids.webp" alt="">
          </a>
        </div>
        <div class="title">
          <div class="side-color"></div>
          <h3>Asteroids</h3>
        </div>
        <div class="text">
          <h3>Asteroids is a remastered version of the classic game “Asteroids”. It has some differences in its game rules, and it counts with 3 different color themes. This game also has background music and, as well as multimedia controls in its options menu. </h3>
        </div>
        <div class="button-container">
          <div class="button" @click="gotoasteroids">
            <p>More Info</p>
          </div>
        </div>
      </div>
      <div class="left-work">
        <div class="row">
          <div class="col-2 first-col"></div>
          <div class="col-4 second-col work-img" data-aos="fade-left" data-aos-duration='1000'>
            <a href="https://github.com/dlmalhao/KoinK">
              <img id="buckle" src="../assets/images/koinkProfile.webp" alt="">
            </a>
          </div>
          <div class="col-4 third-col align-center writing" data-aos="fade-right" data-aos-duration='1000'>
            <div class="content">
              <h3>College Project</h3>
              <h4 class="title">KoinK</h4>
              <div class="description">
                <h3>KoinK is a mobile app made with React Native designed to help kids by teaching them "what is money". It has <span class="red">two minigames</span> as well as a <span class="red">store</span> and a <span class="red">challenges</span> section.</h3>
              </div>
              <div class="work-tools">
                <h4>React Native</h4>
                <h4>Mongoose</h4>
                <h4>Koink REST API</h4>
                <h4>Node.js</h4>
              </div>
              <div class="links">
                <a href="https://github.com/dlmalhao/KoinK">
                  <i class="bi bi-github"></i>
                </a>
                <a href="https://www.behance.net/gallery/161523959/KoinK-Mobile-APP" class="secondIcon">
                  <i class="bi bi-behance"></i>
                </a>
              </div>
            </div>
          </div>
          <div class="col-2 fourth-col"></div> 
        </div>
      </div>
      <div class="work-mobile" data-aos="fade-right" data-aos-duration='1000'>
        <div class="image">
          <a href="https://github.com/dlmalhao/KoinK">
            <img id="buckle" src="../assets/images/koinkProfile.webp" alt="">
          </a>
        </div>
        <div class="title">
          <div class="side-color"></div>
          <h3>KoinK</h3>
        </div>
        <div class="text">
          <h3>Buckle is a website, made in 2021/2022, designed to help and connect all students of a certain college. It has an Ads section that provides a space for the students to ask for some help to the other students. It also has a Projects section to publish their college projects.</h3>
        </div>
        <div class="button-container">
          <div class="button" @click="gotokoink">
            <p>More info</p>
          </div>
        </div>
      </div>
    </div>
    <div class="sector3" >
      <div class="row" id="sector3">
        <div class="col-2"></div>
        <div class="col-8 header">
          <h4 class="text-overlay magic-hover magic-hover__square black" data-aos="fade-up" data-aos-duration='750'>ABOUT</h4>
          <h2 class="textHeader magic-hover magic-hover__square" id="aboutMe" data-aos="fade-down" data-aos-duration='750'>ABOUT</h2>
        </div>
        <div class="col-2"></div>
      </div>
      <div class="about">
        <div class="row">
          <div class="col-2"></div>
          <div class="col-xl-4 left-side" data-aos="fade-right" data-aos-duration='1000'>
            <div class="left-container">
              <div class="paragraph">
                <h3>My name is Diogo Malhão and I’m a Junior Front-End Developer who lives in a beautiful country called Portugal. I really love UI animations and creating dynamic websites.</h3>
              </div>
              <div class="paragraph">
                <h3>I have a good eye for details, so I really enjoy working around perfectionism. I also have a passion for music, playing guitar and piano are really my favourite hobbies, besides programming.</h3>
              </div>
              <div class="paragraph">
                <h3>I'm open for new oportunities. I will always give my best for the team and for the company and I’m willing to keep learning and to help others.</h3>
              </div>
              <div class="button-div">
                <a href="mailto:dlmalhao@gmail.com">
                  <div class="button">
                    <h3>Let's work together !</h3>
                  </div>
                </a>
              </div>
            </div>
          </div>
          <div class="col-xl-4 right-side" data-aos="fade-left" data-aos-duration='1000'>
            <div class="yellow-container">
              <h3>SKILLS</h3>
            </div>
            <div class="black-container">
              <div class="text">
                <h3 @mouseenter="textAnimation"><span class="red">o</span>HTML</h3>
                <h3 @mouseenter="textAnimation"><span class="red">o</span>CSS</h3>
                <h3 @mouseenter="textAnimation"><span class="red">o</span>Javascript</h3>
                <h3 @mouseenter="textAnimation"><span class="red">o</span>Bootstrap</h3>
                <h3 @mouseenter="textAnimation"><span class="red">o</span>MySQL</h3>
                <h3 @mouseenter="textAnimation"><span class="red">o</span>Git</h3>
                <h3 @mouseenter="textAnimation"><span class="red">o</span>MongoDB</h3>
                <h3 @mouseenter="textAnimation"><span class="red">o</span>Vue JS</h3>
                <h3 @mouseenter="textAnimation" id="nodejs"><span class="red">o</span>Node.js</h3>
              </div>  
            </div>
          </div>
          <div class="col-2"></div>
        </div>
      </div>
    </div>
    <div class="sector4" id="sector4">
      <div class="row">
        <div class="col-2"></div>
        <div class="col-8 header">
          <h4 class="text-overlay magic-hover magic-hover__square white" data-aos="fade-up" data-aos-duration='750'>CONTACTS</h4>
          <h2 class="textHeader magic-hover magic-hover__square" id="myContacts" data-aos="fade-down" data-aos-duration='750'>CONTACTS</h2>
        </div>
        <div class="col-2"></div>
      </div>
      <div class="contacts">
        <div class="row">
          <div class="col-2"></div>
          <div class="col-8 social">
            <div class="left-container social-media" data-aos="fade-right" data-aos-duration='750' @click="gotogithub">
              <div class="text">
                <i class="bi bi-github"></i>
              </div>
            </div>
            <div class="middle-container social-media" data-aos="fade-up" data-aos-duration='750' @click="gotolinkedin">
              <div class="text">
                <i class="bi bi-linkedin"></i>
              </div>
            </div>
            <div class="right-container social-media" data-aos="fade-left" data-aos-duration='750' @click="gotoemail">
              <div class="text">
                <i class="bi bi-envelope-fill"></i>
              </div>
            </div>
          </div>
          <div class="col-2"></div>
        </div>
      </div>
      <div class="scrollUp">
        <div>
          <a href="#sector1" class="icon"><i class="bi bi-arrow-up-circle-fill"></i></a>
          <a href="#sector1" class="text"><h3>Scroll up !</h3></a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { setTimeout } from 'timers';
import luxy from 'luxy.js'

export default {

  data() {
    return {
      isLocationActive: false
    }
  },


  mounted () {

    let elements = []
    var path = document.querySelector('#svgLine');
    var length = path.getTotalLength();
    path.style.strokeDasharray = length + ' ' + length;
    path.style.strokeDashoffset = length;

    function mapRange(n, fromStart, fromEnd, toStart, toEnd) {
      const out = toStart + (n - fromStart) * ((toEnd - toStart) / (fromEnd - fromStart));
      // Exclude any values outside to toStart->toEnd range
      return Math.min(toEnd, Math.max(toStart, out));
    }




    var Touch = 'ontouchstart' in window || navigator.msMaxTouchPoints;
    if (typeof Touch === 'undefined') {
    // Init Luxy.js
      luxy.init({
        wrapper: '#luxy',
        wrapperSpeed: 0.065,
      });

    }
    else {document.body.style.display = 'block'}




    window.addEventListener("scroll", (e) => {
      let drawLength
      var scrollPercentage = (document.documentElement.scrollTop + document.body.scrollTop) / (document.documentElement.scrollHeight - document.documentElement.clientHeight);
      if(window.innerWidth < 651) {
        if(window.innerWidth < 570) {
          drawLength = length * mapRange(scrollPercentage, 0.10, 0.63, 0, 1);
          if(scrollPercentage > 0.436) {
            path.style.strokeDashoffset = 554;
          }
          else {
            path.style.strokeDashoffset = length - drawLength;
          }
        }
        else {
          drawLength = length * mapRange(scrollPercentage, 0.18, 0.82, 0, 1);
          path.style.strokeDashoffset = length - drawLength;
        }
      } 
      else {
        drawLength = length * mapRange(scrollPercentage, 0.18, 0.6, 0, 1);
        path.style.strokeDashoffset = length - drawLength;
      }

    })

    setTimeout(()=>{
      for(let lett of document.querySelectorAll(".letter")) {
        lett.classList.remove('letterAnimating')
      }
    },850)

    elements.push(document.querySelector("#myWork"))
    elements.push(document.querySelector("#aboutMe"))
    elements.push(document.querySelector("#myContacts"))

    for(let element of elements) {
      element.addEventListener("mouseenter", () => {
        element.previousSibling.classList.add('letterAnimating')
        setTimeout(()=>{
          element.previousSibling.classList.remove('letterAnimating')
          elements.splice(elements.indexOf(element), 1)
        },850)
      })
    }
  },

  methods: {
    textAnimation(letter) {
      letter.toElement.classList.add('letterAnimating')
      setTimeout(()=>{
        letter.toElement.classList.remove('letterAnimating')
      },850)
    },
    
    showLocation () {
      this.isLocationActive = !this.isLocationActive

      if(this.isLocationActive) {
        document.querySelector(".locationContainer").classList.remove("opacityDecrease")
        document.querySelector(".locationContainer").classList.add("opacityIncrease")
      }
      else {
        document.querySelector(".locationContainer").classList.remove("opacityIncrease")
        document.querySelector(".locationContainer").classList.add("opacityDecrease")
      }
    },

    gotogithub () {
      window.location = 'https://github.com/dlmalhao'
    },

    gotolinkedin() {
      window.location = 'https://www.linkedin.com/in/diogo-malh%C3%A3o-223945162/'
    },

    gotoemail() {
      window.location = 'mailto:dlmalhao@gmail.com'
    },

    gotoasteroids() {
      window.location = 'https://github.com/dlmalhao/Asteroids-Game'
    },

    gotonba() {
      window.location = 'https://github.com/dlmalhao/Three-js-project'
    },

    gotobuckle() {
      window.location = 'https://github.com/dlmalhao/Buckle'
    },

    gotokoink() {
      window.location = 'https://github.com/dlmalhao/KoinK'
    }
  }

}
</script>

<style scoped>

  .loadingScreen {
    position: fixed;
    content: "";
    pointer-events: none;
    height: 100vh;
    width: 100vw;
    object-fit: cover;
    left: 0;
    z-index: 5;
    background-color: #ff0000;
  }
  .page {
    color: var(--white);
    font-family: Coolvetica;
    transition: all 2s ease-in-out;
    cursor: default;
    overflow: hidden;
  }

  .sector1 {
    height: 100vh;
    font-family: Coolvetica;
    background-color: #161616;
  }

  .top-nav {
    width: 100%;
    position: absolute;
    padding: 30px 40px;
    display: flex;
    justify-content: space-between;
    z-index: 3;
  }

  .top-nav a, .bottom-nav p{
    color: #f0f0f0;
    text-decoration: none;
    font-family: 'FuturaPT', sans-serif;
    margin-bottom: 0;
  }



  .top-nav a:hover {
    background: linear-gradient(-90deg, #ff4646, #ffed46, #47ce2c);
	  background-size: 600% 600%;
    color: white;
    background-clip: text;
    text-fill-color: transparent;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
	  animation: gradient 1s ease infinite;
  }

  .bottom-nav {
    width: 100%;
    position: absolute;
    padding: 30px 40px;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    z-index: 2;
    height: 100vh;
  }
  

  .noise-effect::before {
    position: fixed;
    content: "";
    background-image: url(../assets/images/noise.gif);
    opacity: 0.03;
    pointer-events: none;
    height: 100%;
    width: 100vw;
    object-fit: cover;
    left: 0;
  }

  .sector2 {
    background-color: #f0f0f0;
  }

  .sector1 .row {
    height: 100%;
  }

  .sector1 .col-4 {
    z-index: 4;
  }

  .sector1 .location {
    position: absolute;
    margin-left: 60px;
    margin-top: 55px;
    cursor: pointer;
  }

  .scrollArrow {
    width: 100%;
    z-index: 1;
    height: 100vh;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: flex-end;
  }

  .scrollArrow i {
    margin-bottom: 20px;
    font-size: 40px;
  }

  .sector1 .location i {
    font-size: 30px;
    background: linear-gradient(-90deg, #ff4646, #ffed46, #47ce2c);
	  background-size: 600% 600%;
    color: white;
    background-clip: text;
    text-fill-color: transparent;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
	  animation: gradient 5s ease infinite;
    transition: all 0.2s ease-in-out;
  }

  .sector1 .location i:hover {
    animation: gradient 1s ease infinite;
  }

  .sector1 .locationContainer .content {
    height: 100%;
  }

  .sector1 .locationContainer {
    position: absolute;
    width: 300px;
    height: 250px;
    margin-top: 120px;
    right: 55px;
    opacity: 0;
    transition: all 0.2s ease-in-out;
    box-shadow: rgb(0 0 0 / 15%) 0px 5px 15px 0px;
  }

  .sector1 .locationContainer .header{
    display: flex;
    padding: 10px 0 10px 20px;
    background-color: #efefef;
  }
  .sector1 .locationContainer .header h3 {
    font-size: 16px;
    margin: 0;
    color: #3d3d3d;
  }

  .sector1 .locationContainer .content img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .sector1 .right {
    z-index: 0;
  }

  .sector1 .row .left {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }


  .left .flex {
    display: flex;
  }

  .sector1 .paragraph1 span {
    color: #f0f0f0;
    font-size: 100px;
    align-items: flex-end;
    line-height: 80px;
    letter-spacing: 2px;
    transition: all 0.2s ease-in-out;
    position: relative;
    bottom: 0px;
    text-shadow: 2px 4px 3px rgba(0, 0, 0, 0.2);
  }

  .sector1 .paragraph1 span:hover, .sector1 .paragraph2 span:hover {
    background: linear-gradient(-90deg, #ff4646, #ffed46, #47ce2c);
	  background-size: 600% 600%;
    color: white;
    background-clip: text;
    text-fill-color: transparent;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
	  animation: gradient 3s ease infinite;
    transition: all 0.2s ease-in-out;
    bottom: 4px;
  }

  .sector1 .paragraph2 span {
    color: #f0f0f0;
    font-size: 100px;
    line-height: 80px;
    letter-spacing: 2px;
    transition: all 0.2s ease-in-out;
    position: relative;
    bottom: 0px;
    text-shadow: 2px 4px 3px rgba(0, 0, 0, 0.2);
  }

  .sector1 .paragraph1, .sector1 .paragraph2 {
    height: 100px;
  }

  .letterAnimating {
    transition: all 0.2s ease-in-out;
    animation: deform-animation 0.8s ease;
  }

  .opacityIncrease {
    animation: opacity-increase-animation 0.5s ease-in-out;
    animation-fill-mode: forwards; 
  }

  .opacityDecrease {
    animation: opacity-decrease-animation 0.2s ease-in-out;
    animation-fill-mode: forwards; 
  }


  .sector1 .paragraph1 {
    display: flex;
  }

  .sector1 .paragraph2 {
    display: flex;
  }

  .left .flex img {
    margin-left: 10px;
    margin-right: 2px;
  }

  #hi {
    margin-bottom: 0;
  }

  #front-end-developer {
    color: #949494;
    font-size: 22px;
    margin-top: 15px;
    margin-bottom: 0;
  }

  .left .button {
    background: linear-gradient(-90deg, #ff4646, #ffed46, #47ce2c);
	  background-size: 600% 600%;
	  animation: gradient 8s ease infinite;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px;
    cursor: pointer;
    transition: all 0.1s ease;
  }


  .sector2 .work-mobile {
    margin: 50px 50px;
    z-index: 1000;
    background-color: #161616;
    padding-bottom: 20px;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;
    /* border-radius: 10px; */
  }

  .work-mobile .image a img {
    width: 100%;
    object-fit: cover;
    filter: grayscale(100%);
    /* border-radius: 10px 10px 0 0; */
  }

  .work-mobile .title {
    padding: 10px 2px 10px 2px;
    display: flex;
    justify-content: center;
    background-color: #000000;
  }

  .work-mobile .text {
    padding: 15px 25px 0px 25px;
    text-align: left;
  }

  .work-mobile .text h3 {
    font-size: 14px;
    color: #f0f0f0;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.116);
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 5;
  }

  .work-mobile .title h3 {
    font-size: 15px;
    letter-spacing: 1px;
    margin: 0;
  }

  .work-mobile .button-container {
    display: flex;
    justify-content: center;
  }

  .work-mobile .button-container .button {
    display: flex;
    justify-content: center;
    align-items: center;
    background: linear-gradient(-90deg, #ff4646, #ffed46, #47ce2c);
	  background-size: 600% 600%;
	  animation: gradient 8s ease infinite;
    width: 120px;
    height: 32px;
    /* border-radius: 5px; */
    margin-top: 10px;
    transition: all 0.2s ease-in-out;
  }


  .work-mobile .button-container .button:hover {
    background-color: #353535;
    cursor: pointer;
  }

  .work-mobile .button-container .button p {
    text-decoration: none;
    color: #f6f4f2;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.116);
    margin: 0;
  }

  .sector1 .left a {
    margin-top: 40px;
    color: white;
    text-decoration: none;
  }

  .left .button:hover {
    animation: gradient 1s ease infinite;
    transition: all 1s ease;
  }

  .left .button:hover h4 {
    transition: all 1s ease;
  }

  .left .button h4 {
    margin-bottom: 0;
    font-size: 22px;
    color: #f0f0f0;
    transition: all 1s ease;
  }

  .right {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
  }

  .imgMapa {
    background: url(../assets/images/mapa.svg);
    width: 280px;
    height: 530px;
    background-repeat: no-repeat;
  }

  .imglogo {
    width: 80px;
    transition: all 0.2s ease-in-out;
  }

  .work-img img{
    width: 100%;
    object-fit: cover;
    height: 380px;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;
    /* border-radius: 10px; */
    transition: all 0.5s ease-in-out;
    filter:grayscale(100%);
  }

  .sector2 .work-mobile {
    display: none;
  }

  .work-img img:hover {
    filter:grayscale(0%);
    transition: all 0.5s ease;
  }

  .work-mobile .side-color {
    display: none;
  }

  .sector2{
    padding-top: 150px;
    padding-bottom: 150px;
  }

  .sector3, .sector4 {
    padding-top: 150px;
    padding-bottom: 150px;
  }

  

  .sector2 .header, .sector3 .header, .sector4 .header {
    display: flex;
    align-items: flex-end;
    justify-content: center;
  }

  .right-work {
    margin-top: 150px;
  }

  .left-work {
    margin-top: 200px;
  }

  .sector2 .header h3, .sector2 .header h4, .sector3 .header h3, .sector3 .header h4, .sector4 .header h3, .sector4 .header h4 {
    margin-bottom: 0;
    position: absolute;
    display: flex;
    top: 3px;
    align-items: flex-end;
    padding-bottom: 6px;
    justify-content: center;
    z-index: 0;
    letter-spacing: 10px;
  }

  .sector2 .header .text-overlay, .sector3 .header .text-overlay, .sector4 .header .text-overlay {
    font-size: 250px;
    color: #e4e4e4;
    margin: 0;
    position: absolute;
    cursor: default;
    letter-spacing: 40px;
    top: -75px;
  }

  .textHeader {
    z-index: 0;
    color: var(--white);
    font-weight: bold;
    font-size: 120px;
    letter-spacing: 10px;
    text-shadow: 1px 1px 3px #adadad88;
    cursor: default !important;
  }

  #myWork {
    color: #161616;
  }


  .sector2 .header h4, .sector3 .header h4, .sector4 .header h4 {
    font-size: 26px;
    color: #ff4646 ;
    margin-right: 10px;
  }

  .sector2 .header h3, .sector3 .header h3, .sector4 .header h3 {
    font-size: 127px;
    min-width: 220px;
    cursor: default !important;
  }
  
/* .sector2 .header h3 {
  color: #cacaca ;
  text-shadow: 1px 1px 3px #adadad88;
} */

.sector3 .header h3 {
  color: #67b6c1;
  text-shadow: 1px 1px 3px #57575788;
}


.sector3 .textHeader {
  text-shadow: 1px 1px 3px #adadad88;
}

  .sector3 {
    background-color: #161616;
  }

  #aboutMe {
    color: #f0f0f0;
  }

  .sector3 .header .text-overlay.black {
    color: #202020;
  }

  .sector2 .right-work .content {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }

  .sector2 .content h3 {
    color: #161616;
    font-size: 17px;

  }

  .sector2 .content h4 {
    color: #161616;
    font-size: 20px;
    margin-bottom: 15px;
  }

  .sector2 .content .title {
    color: #161616;
    font-style: italic;
  }

  .sector2 .description {
    background-color: #161616 !important;
    padding: 25px;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;
    /* border-radius: 10px; */
  }

  .sector2 .description h3 {
    color: #f0f0f0 !important;
    font-size: 16px;
    text-shadow: none;
  }

  .sector2 .right-work .description h3 {
    text-align: end;
  }

  .sector2 .work-tools {
    display: flex;
    flex-wrap: wrap;
    margin-top: 15px;
  }

  .sector2 .right-work .work-tools h4 {
    font-size: 16px;
    margin-left: 35px;
  }

  .sector2 .right-work .work-img {
    padding-right: 50px;
  }

  .sector2 .right-work .writing {
    padding-left: 50px;
  }

  .sector2 .left-work .work-img {
    padding-left: 50px;
  }

  .sector2 .left-work .writing {
    padding-right: 50px;
  }

  .sector2 .content .links i {
    font-size: 25px;
    color: #3d3d3d;
    transition: all 0.3s ease-in-out;
  }

  .sector2 .content .links .secondIcon {
    margin-left: 25px;
  }

  .sector2 .content .links i:hover {
    transition: all 0.3s ease-in-out;
    background: linear-gradient(-90deg, #ff4646, #ffed46, #47ce2c);
	  background-size: 800% 800%;
	  animation: gradient 3s ease infinite;
    text-fill-color: transparent;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  span.red {
    background: linear-gradient(-90deg, #ff4646, #ffed46, #47ce2c);
	  background-size: 800% 800%;
	  animation: gradient 10s ease infinite;
    text-fill-color: transparent;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 16px;
    font-weight: lighter;
    transition: all 0.5s ease-in-out;
    text-shadow: 1px 1px 2px rgba(66, 66, 66, 0.082) 
  }

  .align-center {
    display: flex;
    align-items :center;
  }

  .sector2 .left-work .first-col {
    order: 1;
  }

  .sector2 .left-work .second-col{
    order: 3;
  }
  .sector2 .left-work .third-col {
    order: 2;
  }

  .sector2 .left-work .fourth-col {
    order: 4;
  }

  #buckle {
    object-position: left;
  }

  .sector2 .left-work .description h3 {
    color: var(--white);
    text-align: start;
  }

  .sector2 .left-work .content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
  }

  .sector2 .left-work .work-tools h4{
    font-size: 16px;
    margin-right: 35px;
  }

  .about .left-container {
    background-color: #f0f0f0 ;
    padding: 40px 75px 40px 75px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;
    /* border-radius: 10px; */
  }

  .about .left-container h3 {
    font-size: 18px;
    text-align: left;
    color: #3d3d3d;
    line-height: 20px;
    font-family: Coolvetica;
  }

  .about .left-container .paragraph {
    margin-bottom: 25px;
  }

  .about .left-container .button-div a {
    text-decoration: none;
  }

  .about .left-container .button-div .button {
    background: #161616;
    width: 250px;
    /* border-radius: 5px; */
    padding: 10px;
    transition: all 0.2s ease-in-out;
  }

  .about .left-container .button-div .button h3 {
    background: linear-gradient(-90deg, #ffffff, #808080, #ffffff);
	  background-size: 800% 800%;
	  animation: gradient 3s ease infinite;
    text-fill-color: transparent;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .about .left-container .button-div .button:hover h3 {
    background: linear-gradient(-90deg, #ff4646, #ffed46, #47ce2c);
	  background-size: 600% 600%;
    background-clip: text;
    text-fill-color: transparent;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
	  animation: gradient 3s ease infinite;
  }

  .about .left-container .button-div .button:hover {
    background: #282828;
  }

  .about .left-container .button-div .button h3 {
    display: flex;
    justify-content: center;
    margin: 0;
    transition: all 0.2s ease-in-out;
  }

  .about {
    margin-top: 150px;
  }

  .about .yellow-container {
    background-color: #2e2e2e;
    width: 150px;
    /* border-radius: 10px 0 0 10px; */
    display: flex;
    justify-content: center;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;
    background: linear-gradient(-90deg, #ff4646, #ffed46, #47ce2c);
	  background-size: 600% 600%;
	  animation: gradient 5s ease infinite;
  }

  .about .yellow-container h3 {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
    -webkit-writing-mode: vertical-rl;
    writing-mode: vertical-rl;
    margin: 0;
    font-size: 70px;
    color: var(--white);
  }

  .about .black-container {
    background-color: #f0f0f0 ;
    display: flex;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;
    width: 100%;
    /* border-radius: 0 10px 10px 0; */
  }

  .about .right-side {
    display: flex;
  }

  .about .black-container .text h3{
    font-size: 20px;
    margin-bottom: 30px;
    
    color: #3d3d3d;
  }

  .about .black-container .text span {
    margin-right: 10px;
    color: #ff4646;
  }

  .about .black-container .text {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 40px 60px 40px 100px;
  }

  #nodejs {
    margin-bottom: 0;
  }


  .sector4 .textHeader {
    text-shadow: 1px 1px 3px #adadad88;
  }


  .contacts .left-container, .contacts .middle-container, .contacts .right-container {
    width: 300px;
    height: 280px;
    display: flex;
    align-items: center;
    justify-content: center;
    /* border-radius: 5px; */
    box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;
  }

  .contacts .row {
    margin-top: 150px !important;
  }

  .contacts .col-8 {
    display: flex;
    justify-content: space-between;
  }

  .contacts .left-container {
    margin-right: 20px; 
    overflow: hidden;
    transition: all 0.5s ease;
    background-color: #161616;
    cursor: pointer;
  }

  .contacts .middle-container {
    margin-right: 20px;
    overflow: hidden;
    transition: all 0.5s ease;
    background-color: #161616;
    cursor: pointer;
  }

  .contacts .right-container {
    overflow: hidden;
    transition: all 0.5s ease;
    background-color: #161616;
    cursor: pointer;
  }
  
  .sector4 {
    padding-bottom: 125px;
    background-color: #f0f0f0;
  }

  .sector4 .header h2 {
    color: #161616;
  }

  .contacts .text {
    position: absolute;
  }

  .contacts .left-container .text i {
    font-size: 50px;
  }

  .contacts .middle-container .text i {
    font-size: 50px;
  }

  .contacts .right-container .text i {
    font-size: 50px;
  }

  .contacts .left-container::after {
    content: "";
    display: block;
    width: 100%;
    left: -1000px;
    height: 100%;
    background-size: cover;
    /* border-radius: 5px; */
    position: relative;
    background-image: url(../assets/images/githubProfile.png);
    transition: all 2s ease;
  }
  .contacts .middle-container::after {
    content: "";
    display: block;
    width: 100%;
    left: -1000px;
    height: 100%;
    background-size: cover;
    /* border-radius: 5px; */
    position: relative;
    background-image: url(../assets/images/linkedinProfile.png);
    transition: all 2s ease;
  }
  .contacts .right-container::after {
    content: "";
    display: block;
    width: 100%;
    left: -1000px;
    height: 100%;
    background-size: cover;
    /* border-radius: 5px; */
    position: relative;
    background-image: url(../assets/images/email.png);
    transition: all 2s ease;
  }

  .contacts .left-container:hover::after, .contacts .middle-container:hover::after, .contacts .right-container:hover::after{
    transition: all 1s ease;
    left: 0;
  }

  .contacts .left-container:hover, .contacts .middle-container:hover , .contacts .right-container:hover  {
    transition: all 0.5s ease;
  }

  .contacts .left-container .text i, .contacts .middle-container .text i , .contacts .right-container .text i  {
    transition: all 0.5s ease;
    text-shadow: none;
    background: linear-gradient(-90deg, #ff4646, #ffed46, #47ce2c);
	  background-size: 600% 600%;
    background-clip: text;
    text-fill-color: transparent;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
	  animation: gradient 3s ease infinite;
    -webkit-animation: gradient 3s ease infinite;
  }

  .contacts .left-container:hover .text i, .contacts .middle-container:hover .text i , .contacts .right-container:hover .text i {
    background: linear-gradient(-90deg, #ededed, #252525, #9e9e9e);
    background-size: 600% 600%;
    background-clip: text;
    text-fill-color: transparent;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
	  animation: gradient 3s ease infinite;
    -webkit-animation: gradient 3s ease infinite;
  }

  .sector2 .svgContainer {
    display: flex;
    width: 100%;
    justify-content: center;
  }

  .sector2 svg {
    position: absolute;
    margin-top: 150px;
  }

  .sector1button {
    animation: shake-animation 4.72s ease infinite;
    transform-origin: 50% 50%;
  }

  .color-theme {
    margin-top: 20px;
    display: flex;
    justify-content: flex-end;
  }

  .color1, .color2, .color3, .color4 {
    width: 22px;
    height: 22px;
    margin-left: 10px;
    /* border-radius: 50%; */
    border: 1px solid var(--white);
  }

 .scrollUp div i{
  font-size: 32px;
  background: linear-gradient(-90deg, #ff4646, #ffed46, #47ce2c);
	background-size: 600% 600%;
	animation: gradient 3s ease infinite;
  -webkit-animation: gradient 3s ease infinite;
  text-fill-color: transparent;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
 }

 .scrollUp div {
  display: flex;
  align-items: center;
  flex-direction: column;
 }

 #locationTag {
  animation: upAndDown-animation 2s ease infinite;
 }

 .scrollUp {
  margin-top: 85px;
  overflow: hidden;
 }

 .scrollUp div h3 {
  margin-top: 10px;
  color: #161616;
  text-shadow: 1px 1px 2px #c4c4c4;
  transform-origin: 50% 50%;
  width: 130px;
  transition: all 0.2s ease-in-out;
 }

 .scrollUp div i {
  transition: all 0.2s ease-in-out;
 }

 .scrollUp div .icon {
  animation: upAndDown-animation 1s ease infinite;
 }

 .scrollUp div .text {
  animation: shake-animation 3s ease infinite;
 }

 .scrollUp div a {
  text-decoration: none ;
  color: var(--white);
 }

 .scrollUp div .text:hover h3 {
  text-shadow: none;
  background: linear-gradient(-90deg, #ff4646, #ffed46, #47ce2c);
	background-size: 600% 600%;
  background-clip: text;
  text-fill-color: transparent;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
	animation: gradient 3s ease infinite;
  transition: all 0.2s ease-in-out;
 }

 .scrollUp div .icon:hover i {
  text-shadow: none;
  background: linear-gradient(-90deg, #ff4646, #ffed46, #47ce2c);
	background-size: 600% 600%;
  background-clip: text;
  text-fill-color: transparent;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
	animation: gradient 3s ease infinite;
  transition: all 0.2s ease-in-out;
 }



  @keyframes shake-animation {
    0% { transform:translate(0,0) }
    1.78571% { transform:translate(5px,0) }
    3.57143% { transform:translate(0,0) }
    5.35714% { transform:translate(5px,0) }
    7.14286% { transform:translate(0,0) }
    8.92857% { transform:translate(5px,0) }
    10.71429% { transform:translate(0,0) }
    100% { transform:translate(0,0) }
  }

  @-webkit-keyframes shake-animation {
    0% { -webkit-transform:translate(0,0) }
    1.78571% { -webkit-transform:translate(5px,0) }
    3.57143% { -webkit-transform:translate(0,0) }
    5.35714% { -webkit-transform:translate(5px,0) }
    7.14286% { -webkit-transform:translate(0,0) }
    8.92857% { -webkit-transform:translate(5px,0) }
    10.71429% { -webkit-transform:translate(0,0) }
    100% { -webkit-transform:translate(0,0) }
  }

  @keyframes upAndDown-animation {
    0% { transform: translateY(10px) }
    70% { transform: translateY(0px) }
    100% { transform: translateY(10px) }
  }

  @-webkit-keyframes upAndDown-animation {
    0% { -webkit-transform: translateY(10px) }
    70% { -webkit-transform: translateY(0px) }
    100% { -webkit-transform: translateY(10px) }
  }

  @keyframes gradient {
	  0% {
	  	background-position: 0% 50%;
	  }
	  50% {
	  	background-position: 100% 50%;
	  }
	  100% {
	  	background-position: 0% 50%;
	  }
  }

  @-webkit-keyframes gradient {
	  0% {
	  	-webkit-background-position: 0% 50%;
	  }
	  50% {
	  	-webkit-background-position: 100% 50%;
	  }
	  100% {
	  	-webkit-background-position: 0% 50%;
	  }
  }

  @keyframes opacity-increase-animation {
    0% { opacity: 0;}
    100% { opacity: 1; }
  }

  @-webkit-keyframes opacity-increase-animation {
    0% { -webkit-opacity: 0;}
    100% { -webkit-opacity: 1; }
  }

  @keyframes opacity-decrease-animation {
    0% { opacity: 1;}
    100% { opacity: 0; }
  }

  @-webkit-keyframes opacity-decrease-animation {
    0% { -webkit-opacity: 1;}
    100% { -webkit-opacity: 0; }
  }

  @keyframes deform-animation {
    0% { transform: skew(0deg); }
    20% { transform: skew(10deg); }
    55% { transform: skew(-10deg); }
    70% { transform: skew(10deg); }
    85% { transform: skew(-10deg); }
    100% { transform: skew(0deg); }
  }

  @-webkit-keyframes deform-animation {
    0% { -webkit-transform: skew(0deg); }
    20% { -webkit-transform: skew(10deg); }
    55% { -webkit-transform: skew(-10deg); }
    70% { -webkit-transform: skew(10deg); }
    85% { -webkit-transform: skew(-10deg); }
    100% { -webkit-transform: skew(0deg); }
  }





  @media only screen and (max-width: 1500px){
    .about .black-container .text {
      padding: 40px 60px 40px 70px;
    }
  }



  @media only screen and (max-width: 1350px){
    .about .black-container .text {
      padding: 40px 60px 40px 60px;
    }

    .about .left-container h3 {
      font-size: 16px;
    }

    .about .left-container .button-div .button {
      width: 100%;
    }
  }


  @media only screen and (max-width: 1200px) {
    .sector1 .paragraph1 span, .sector1 .paragraph2 span {
      font-size: 82px;
    }

    .imglogo {
      width: 70px;
    }

    .sector2 {
      padding-top: 100px;
    }

    #front-end-developer {
      font-size: 20px;
    }

    .textShadow {
      font-size: 110px !important;
    }

    .textHeader {
      font-size: 104px;
    }

    .work-img {
      width: 600px;
      padding: 0 !important;
    }

    .sector2 .row {
      justify-content: space-around;
    }

    .sector2 .col-2 {
      display: none;
    }

    .sector2 .left-work {
      margin-top: 150px;
    }

    .col-4 {
      padding: 0 !important;
    }

    .sector3 .col-xl-4 {
      width: 600px;
    }

    .sector3 .row {
      justify-content: center;
    }

    .sector3 .col-2 {
      display: none;
    }

    .about .black-container .text h3 {
      margin-bottom: 15px;
      font-size: 18px;
    }

    .sector3 .right-side {
      margin-bottom: 75px;
    }

    .sector3 .left-side {
      order: 1;
    }

    .sector3 {
      padding-top: 100px;
    }

    .sector3 .about {
      margin-top: 100px;
    }

    .sector4 {
      padding-top: 100px;
    }

    .sector4 .contacts .row {
      margin-top: 100px !important;
    }

    .contacts .right-container .text h3, .contacts .left-container .text h3, .contacts .middle-container .text h3 {
      font-size: 25px;
    }
  }

  @media only screen and (max-width: 1150px) {
    
  }

  @media only screen and (max-width: 1000px) {
    .sector1 .paragraph1 span, .sector1 .paragraph2 span {
      font-size: 75px;
    }

    .sector1 .left .button h4 {
      font-size: 20px;
    }

    .sector1 .col-2 {
      display: none;
    }

    .sector1 .row {
      justify-content: space-around;
    }

    .sector4 .col-2 {
      display: none;
    }

    .sector4 .row {
      justify-content: center;
    }

    .textShadow {
      font-size: 100px !important;
    }

    .textHeader {
      font-size: 94px;
    }

    .sector4 .col-8.social {
      max-width: fit-content; 
    }
  }

  @media only screen and (max-width: 850px) {
    .footer .row img {
      width: 35px;
      height: 35px;
    }
  }


  @media only screen and (max-width: 800px) {
    .sector1 .col-4 {
      max-width: fit-content;
    }

    .sector1 .col-4 .left .letters {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .sector1 .col-4 .left {
      align-items: center;
    }

    .sector1 .col-4.map {
      display: none;
    }
  }


  @media only screen and (max-width: 650px) {

    .svgContainer svg{
      display: none;
    }

    .top-nav{
      font-size: 14px;
    }

    .imglogo {
      margin-bottom: 10px;
    }

    .scrollArrow i {
      margin-bottom: 70px;
    }

    .bottom-nav {
      display:  none;
    }

    .sector2 .header .text-overlay {
      font-size: 200px;
      letter-spacing: 30px;
    }

    .sector3 .header .text-overlay {
      font-size: 200px;
      letter-spacing: 30px;
    }

    .sector4 .header .text-overlay {
      font-size: 200px;
      letter-spacing: 30px;
    }

    .yellow-container h3 {
      font-size: 55px !important;
    }

    .scrollUp h3 {
      font-size: 20px;
    }

    .scrollUp .icon i {
      font-size: 24px;
    }

    .footer .row img {
      width: 30px;
      height: 30px;
    }

    .textShadow {
      font-size: 90px !important;
    }

    .textHeader {
      font-size: 85px;
    }

    .sector2 {
      padding-top: 70px;
    }

    .sector2 .right-work, .sector2 .left-work {
      display: none;
    }

    .sector2 .work-mobile {
      display: block;
    }

    .sector2 .writing {
      display: none;
    }

    .sector2 svg {
      margin-top: 100px;
    }

    .work-img {
      margin: 0 !important;
    }

  }

  @media only screen and (max-width: 570px) {
    .footer .row img {
      width: 20px;
      height: 20px;
    }

    .scrollArrow i {
      font-size: 30px;
    }

    .sector2 .header .text-overlay {
      font-size: 120px;
      top: -40px;
      letter-spacing: 20px;
    }

    .sector3 .header .text-overlay {
      font-size: 120px;
      top: -40px;
      letter-spacing: 20px;
    }

    .sector4 .header .text-overlay {
      font-size: 120px;
      top: -40px;
      letter-spacing: 20px;
    }

    .textShadow {
      font-size: 60px !important;
    }

    .textHeader {
      font-size: 55px;
    }

    .about .black-container .text {
      padding: 20px 0 20px 20px;
    }

    .sector4 .col-8.social {
      display: flex;
      flex-direction: column;
    }

    .sector4 .left-container,.sector4 .right-container, .sector4 .middle-container {
      width: 220px;
      height: 220px;
      margin-bottom: 20px;
      margin-right: 0;
      margin-left: 0;
    }

    .about .left-container h3 {
      font-size: 15px;
    }

    .footer .row {
      padding-right: 20px;
      padding-left: 20px;
    }

    .footer .col {
      padding: 0px;
      justify-content: center;
    }

    .contacts .right-container .text h3, .contacts .left-container .text h3, .contacts .middle-container .text h3 {
      font-size: 20px;
    }

    .about .black-container .text h3 {
      font-size: 16px;
    }
    
  }

  @media only screen and (max-width: 500px) {
    .work-mobile .text {
      display: none;
    }

    .sector2 .work-mobile {
      padding-bottom: 0;
    }

    .work-mobile .button-container {
      display: none;
    }

    .work-mobile .title  {
      background: none;
      width: 100%;
      background: #161616;
      height: 50px;
      text-shadow: none;
    }

    .work-mobile .title h3 {
      display: flex;
      align-items: center;
      text-shadow: none;
      color: #f0f0f0;
    }

    .work-mobile .side-color {
      display: block;
      position: absolute;
      bottom: 0;
      left: 0;
      width: 20px;
      height: 50px;
      background: linear-gradient(-90deg, #ff4646, #ffed46, #47ce2c);
	    background-size: 800% 800%;
	    animation: gradient 5s ease infinite;
    }

    .work-mobile .image a img {
      height: 250px;
    }
  }

</style>